import { Button, Form, Modal } from 'antd';
import { useImageDetail, useUpdateImage } from 'api/imageLibraryApi';
import NameForm from 'components/formItem/NameForm';
import UploadImage from 'components/uploadImage/UploadImage';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function UpdateImageLibrary({
  id,
  refetch,
  isModalOpen,
  setIsModalOpen,
}) {
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(true);

  const useUpdateImageMutation = useUpdateImage();

  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      file_id: imageId,
      ...values,
    };
    useUpdateImageMutation.mutate(
      { id: id, data },
      {
        onSuccess: (response) => {
          toast.success('Cập Nhật Thành Công');
          refetch();
        },
        onError: (error) => {
          if (!imageId) {
            toast.error('Vui Lòng Thêm Hình Ảnh');
          } else {
            toast.error('Cập Nhật Thất Bại');
          }
          setDisableButton(true);
        },
      },
    );
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const {} = useImageDetail({
    id: id,
    options: {
      onSuccess(res) {
        const data = res.data;
        setImageUrl(data?.file_id?.path);
        form.setFieldsValue({
          title_vi: data.title_vi,
          title_en: data.title_en,
          title_ru: data.title_ru,
        });
      },
    },
  });
  return (
    <>
      <Modal
        footer={null}
        title="Chỉnh Sửa Hình Ảnh"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <Form
            className=" relative"
            size="large"
            name="wrap"
            form={form}
            labelCol={{
              flex: '100px',
            }}
            labelWrap
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <NameForm label="Tiêu đề :" name="title_vi" />

            <UploadImage
              setImageId={setImageId}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
            />

            <div className="border-t border-collapse h-[40px] mt-3">
              <Button
                htmlType="submit"
                disable={disableButton}
                className=" absolute right-2 mt-3 bg-blue-500 text-white rounded"
              >
                LƯU
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}
