import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { Modal } from 'components/modal/Modal';
import { OTPForm } from './OTPForm';

export const OTPModal = ({
  onSubmit,
  isLoading = false,
  initialValues,
  ...props
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  return (
    <Modal
      title={intl.formatMessage({ id: 'common.modal.otpTitle' })}
      onOk={() => {
        form.validateFields().then(onSubmit);
      }}
      okButtonProps={{ loading: isLoading }}
      okText={intl.formatMessage({ id: 'common.btn.confirm' })}
      {...props}
    >
      <OTPForm form={form} initialValues={initialValues} />
    </Modal>
  );
};
