import { Form} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import TextArea from 'antd/lib/input/TextArea';
import { useQADetail, useUpdateQA } from 'api/QA';
import SaveButton from 'components/button/SaveButton';
import NameForm from 'components/formItem/NameForm';

export default function UpdateQA() {
  const navigate = useNavigate();
  const { id } = useParams();
  const useUpdateQAMutation = useUpdateQA();
  const [form] = Form.useForm();

  const {} = useQADetail({
    id: id,
    options: {
      onSuccess(res) {
        const data = res.data;
        form.setFieldsValue({
          topic_vi: data.topic_vi,
          topic_en: data.topic_en,
          topic_ru: data.topic_ru,
          question_vi: data.question_vi,
          question_en: data.question_en,
          question_ru: data.question_ru,
          answer_vi: data.answer_vi,
          answer_en: data.answer_en,
          answer_ru: data.answer_ru,
        });
      },
    },
  });
  const onFinish = (values) => {
    const data = {
      ...values,
    };
    useUpdateQAMutation.mutate(
      { id: id, data },
      {
        onSuccess: (response) => {
          toast.success('Cập Nhật Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          toast.error('Cập Nhật Thất Bại');
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className=" text-red-500 title">CHỈNH SỬA CHỦ ĐỀ</div>
      <div>
        <Form
          size="large"
          name="wrap"
          form={form}
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Chủ đề :" name="topic_vi" />

          <Form.Item
            label="Câu Hỏi :"
            name="question_vi"
            rules={[
              { required: true, message: 'Vui lòng nhập nội dung câu hỏi ' },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>

          <Form.Item
            label="Câu Trả Lời :"
            name="answer_vi"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập nội dung câu trả lời ',
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <SaveButton />
        </Form>
      </div>
    </div>
  );
}
