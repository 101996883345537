import { useState } from 'react';
import { Col, Row, Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputTextArea,
  StyledSelect,
} from 'styles/overrides';
import { useDistrictsByProvinceId, useProvinces } from 'api/locationApi';

export const ProfileForm = ({
  initialValues = {},
  onSubmit = () => {},
  isAddNew,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [provinceId, setProvinceId] = useState(
    () => initialValues?.defaultAddress?.provinceId ?? null,
  );
  const { data: provinces = [], isLoadingProvinces } = useProvinces();
  const { data: districts = [], isLoadingDistricts } = useDistrictsByProvinceId(
    { provinceId },
  );

  const handleChangeProvince = (provinceId) => {
    setProvinceId(provinceId);
    form.setFields([{ name: ['defaultAddress', 'districtId'], value: null }]);
  };

  return (
    <StyledForm
      layout="vertical"
      size="large"
      onFinish={onSubmit}
      form={form}
      initialValues={initialValues}
    >
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <StyledFormItem
            name="fullName"
            label={intl.formatMessage({
              id: 'views.customers.form.label.fullName',
            })}
            rules={[{ required: true }]}
          >
            <StyledInput
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.fullName',
              })}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.provinceId',
            })}
            name={['defaultAddress', 'provinceId']}
            rules={[{ required: true }]}
          >
            <StyledSelect
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.provinceId',
              })}
              loading={isLoadingProvinces}
              onChange={handleChangeProvince}
            >
              {provinces.length > 0 &&
                provinces.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </StyledSelect>
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.districtId',
            })}
            name={['defaultAddress', 'districtId']}
            rules={[{ required: true }]}
          >
            <StyledSelect
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.districtId',
              })}
              loading={isLoadingDistricts}
              disabled={!provinceId}
            >
              {districts.length > 0 &&
                districts.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </StyledSelect>
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.customers.form.label.address',
            })}
            name={['defaultAddress', 'address']}
            rules={[{ required: true }]}
          >
            <StyledInputTextArea
              placeholder={intl.formatMessage({
                id: 'views.customers.form.placeholder.address',
              })}
              autoSize={{ minRows: 3 }}
            />
          </StyledFormItem>
        </Col>
      </Row>

      <StyledFormItem style={{ textAlign: 'center' }}>
        <StyledButton type="primary" htmlType="submit">
          {intl.formatMessage({
            id: `common.btn.${isAddNew ? 'addNew' : 'update'}`,
          })}
        </StyledButton>
      </StyledFormItem>
    </StyledForm>
  );
};
