import { useIntl } from 'react-intl';
import { Form } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import { VNMobilePhonePattern } from 'utils/validationUtils';

export const SearchPhoneForm = ({ onSubmit = () => {} }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  return (
    <StyledForm layout="vertical" size="large" onFinish={onSubmit} form={form}>
      <StyledFormItem
        name="phone"
        label={intl.formatMessage({
          id: 'views.profile.numberPhoneCode',
        })}
        rules={[
          { required: true },
          {
            pattern: VNMobilePhonePattern,
            message: intl.formatMessage({
              id: 'validate.invalidPhoneNumber',
            }),
          },
        ]}
      >
        <StyledInput
          placeholder={intl.formatMessage({
            id: 'views.orders.form.placeholder.phone',
          })}
        />
      </StyledFormItem>

      <StyledFormItem style={{ textAlign: 'center' }}>
        <StyledButton type="primary" htmlType="submit">
          {intl.formatMessage({ id: 'common.btn.search' })}
        </StyledButton>
      </StyledFormItem>
    </StyledForm>
  );
};
