import { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Table } from 'components/table';
import {
  StyledButton,
  StyledSpace,
  StyledTypographyText,
} from 'styles/overrides';
import { formatCurrency } from 'utils/formatUtils';
import { OrderItemModal } from '../../views/orders/components/OrderItem';

export const ProductTable = ({
  dataSource = [],
  totalPrice,
  currency,
  isFixedWidth = false,
  isShowActions = false,
  onUpdateOrderItemNameSuccess = () => {},
}) => {
  const intl = useIntl();
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => index + 1,
      width: isFixedWidth ? 60 : '',
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'productCode',
      width: isFixedWidth ? 80 : undefined,
    },
    {
      title: intl.formatMessage({ id: 'components.product.description' }),
      dataIndex: 'productName',
      width: isFixedWidth ? 200 : undefined,
    },
    {
      title: intl.formatMessage({ id: 'components.product.quantity' }),
      dataIndex: 'quantity',
      width: isFixedWidth ? 60 : undefined,
    },
    {
      title: intl.formatMessage({ id: 'components.product.price' }),
      dataIndex: 'price',
      render: (value) => formatCurrency(value),
    },
    {
      title: intl.formatMessage({ id: 'components.product.total' }),
      dataIndex: 'subTotal',
      render: (value) => formatCurrency(value),
    },
    ...(isShowActions
      ? [
          {
            render: (record) => (
              <StyledButton onClick={() => setSelectedRow(record)}>
                {intl.formatMessage({ id: 'common.btn.edit' })}
              </StyledButton>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <Table
        rowKey="productId"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        footer={() => (
          <StyledSpace
          // size={64}
          // style={{
          //   justifyContent: 'flex-end',
          //   paddingRight: 100,
          // }}
          >
            <StyledTypographyText
              style={{ fontSize: 18, color: 'var(--gray700)' }}
            >
              {intl.formatMessage({ id: 'components.product.totalPrice' })}:
            </StyledTypographyText>
            <StyledTypographyText
              style={{ fontSize: 20, fontWeight: 700, color: 'var(--gray900)' }}
            >
              {formatCurrency(totalPrice)} {currency}
            </StyledTypographyText>
          </StyledSpace>
        )}
      />

      {isShowActions && (
        <OrderItemModal
          open={!_.isNil(selectedRow)}
          onCancel={() => {
            setSelectedRow(null);
          }}
          initialValues={selectedRow}
          onSuccess={() => {
            setSelectedRow(null);
            onUpdateOrderItemNameSuccess();
          }}
        />
      )}
    </>
  );
};
