import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import TextEditor from 'components/texteditor/TextEditor';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useActionMenu, useCreatePost } from 'api/postApi';
import UploadImage from 'components/uploadImage/UploadImage';
import NameForm from 'components/formItem/NameForm';
import DescriptionForm from 'components/formItem/DescriptionForm';
import SwitchHome from 'components/switch/SwitchHome';
import SwitchBanner from 'components/switch/SwitchBanner';
import SaveButton from 'components/button/SaveButton';
import Tinymce from 'components/tinymceEditor/Tinymce';

function CreateAction() {
  const [ disableButton, setDisableButton ] = useState(true);
  const [valueEditor, setValueEditor] = useState('');
  const [switchHome, setSwitchHome] = useState(false);
  const [switchBanner, setSwitchBanner] = useState(false);
  const [imageBannerId, setImageBannerId] = useState();
  const [imageBannerUrl, setImageBannerUrl] = useState();
  const [categoryId, setCategoryId] = useState();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { data } = useActionMenu();
  useEffect(() => {
    data?.data?.map((item) => {
      if (slug == item.slug_vi) {
        setCategoryId(item.id);
      }
    });
  });
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const useCreateGuideMutation = useCreatePost();

  const onFinish = (values) => {
    setDisableButton(false)
    const data = {
      is_home: switchHome ? 1 : 0,
      is_banner: switchBanner ? 1 : 0,
      banner_vi: switchBanner ? imageBannerId : '',
      thumbnail_vi: imageId,
      category_id: categoryId,
      content_vi: valueEditor || ' ',
      ...values,
    };
    useCreateGuideMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          toast.success('Thêm Mới Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          if (!imageId) {
            toast.error('Vui Lòng Thêm Hình Ảnh');
          } else {
            toast.error('Thêm Mới Thất Bại');
          }
          setDisableButton(true)
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-blue-500">TẠO MỚI BÀI VIẾT</div>
      <div>
        <Form
          size="large"
          name="wrap"
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="title_vi" />

          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />

          <Tinymce valueEditor={valueEditor} setValueEditor={setValueEditor} />

          <DescriptionForm label="Ghi chú :" name="description_vi" />

          <SwitchHome switchHome={switchHome} setSwitchHome={setSwitchHome} />

          <SwitchBanner
            switchBanner={switchBanner}
            setSwitchBanner={setSwitchBanner}
            setImageBannerId={setImageBannerId}
            imageBannerUrl={imageBannerUrl}
            setImageBannerUrl={setImageBannerUrl}
          />
          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}
export default CreateAction;
