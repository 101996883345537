import { Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useUpdateVideo, useVideoDetail } from 'api/videoLibraryApi';
import { useParams } from 'react-router-dom';
import NameForm from 'components/formItem/NameForm';
import SaveButton from 'components/button/SaveButton';

function UpdateVideoLibrary() {
  const [disableButton, setDisableButton] = useState(true);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const useUpdateVideoMutation = useUpdateVideo();
  const {} = useVideoDetail({
    id: id,
    options: {
      onSuccess(res) {
        console.log('res', res.data);
        const data = res.data;
        form.setFieldsValue({
          title_vi: data?.title_vi,
          title_en: data?.title_en,
          title_ru: data?.title_ru,
          link: data?.link,
        });
      },
    },
  });

  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      ...values,
    };
    // console.log('Success : ', data);
    useUpdateVideoMutation.mutate(
      { id: id, data },
      {
        onSuccess: (response) => {
          // console.log(response);
          toast.success('Cập Nhật Video Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          // console.log('error : ', error);
          toast.error('Cập Nhật Video Thất Bại');
          setDisableButton(true);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-red-500">CHỈNH SỬA BÀI VIẾT</div>
      <div className="mt-[50px]">
        <Form
          size="large"
          name="wrap"
          form={form}
          labelCol={{
            flex: '120px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tiêu đề Video : " name="title_vi" />

          <NameForm label="Liên kết : " name="link" />

          <div className="ml-5">
            <SaveButton disable={disableButton} />
          </div>
        </Form>
      </div>
    </div>
  );
}
export default UpdateVideoLibrary;
