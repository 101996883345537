
import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const menuApi = {
    getMenu(params){
        return axiosClient.get('/menu', {params})
    },
}

export const useMenu = ({params}) => {
    return useQuery({
        queryKey: ['menu'],
        queryFn: async () => {
            return menuApi.getMenu(params)
        },
        
    })
}