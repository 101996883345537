import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function EditButton({slug,selected}) {

  return (
    <div>
        <Button className='bg-yellow-500 mx-2 text-white rounded' disabled={selected.length > 1 || !selected.length}><Link to={slug}>SỬA</Link></Button>
    </div>
  )
}

export default EditButton