import { Form } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import TextEditor from 'components/texteditor/TextEditor';
import { usePost, useUpdatePost } from 'api/postApi';
import NameForm from 'components/formItem/NameForm';
import UploadImage from 'components/uploadImage/UploadImage';
import DescriptionForm from 'components/formItem/DescriptionForm';
import SwitchHome from 'components/switch/SwitchHome';
import SwitchBanner from 'components/switch/SwitchBanner';
import SaveButton from 'components/button/SaveButton';
import Tinymce from 'components/tinymceEditor/Tinymce';
import UpLoadFile from 'components/uploadImage/UpLoadFile';

function UpdateNews() {
  const [valueEditor, setValueEditor] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [switchHome, setSwitchHome] = useState(false);
  const [switchBanner, setSwitchBanner] = useState(false);
  const [imageBannerId, setImageBannerId] = useState();
  const [imageBannerUrl, setImageBannerUrl] = useState();
  const navigate = useNavigate();
  const { slugDetail } = useParams();
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [fileId, setFileId] = useState();
  const [fileUrl, setFileUrl] = useState();
  const useUpdateGuideMutation = useUpdatePost();
  const [form] = Form.useForm();

  const { data } = usePost({
    slug: slugDetail,
    options: {
      onSuccess(res) {
        const data = res.data;
        setImageId(data.thumbnail_vi.id);
        setImageUrl(data.thumbnail_vi.path);
        setSwitchHome(data.is_home);
        setSwitchBanner(data.is_banner);
        setImageBannerId(data?.banner_vi?.id);
        setImageBannerUrl(data?.banner_vi?.path);
        setValueEditor(data?.content_vi);
        setFileUrl(data.file_vi_id.path);
        form.setFieldsValue({
          title_vi: data.title_vi,
          name_en: data.name_en,
          name_ru: data.name_ru,
          // content_vi: data.content_vi,
          // content_en: data.content_en,
          // content_ru: data.content_ru,
          thumbnail_vi: data.thumbnail_vi?.id,
          thumbnail_en: data.thumbnail_en?.id,
          thumbnail_ru: data.thumbnail_ru?.id,
          description_vi: data.description_vi,
          description_en: data.description_en,
          description_ru: data.description_ru,
          file_vi_id: data.file_vi_id?.id,
        });
      },
    },
  });
  const categoryId = data?.data?.category_id;
  const onFinish = (values) => {
    setDisableButton(false)
    const data = {
      is_home: switchHome ? 1 : 0,
      is_banner: switchBanner ? 1 : 0,
      banner_vi: switchBanner ? imageBannerId : '',
      thumbnail_vi: imageId,
      content_vi: valueEditor,
      category_id: categoryId,
      file_vi_id: fileId,
      ...values,
    };
    useUpdateGuideMutation.mutate(
      { slug: slugDetail, data },
      {
        onSuccess: (response) => {
          toast.success('Cập Nhật Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          if (!imageId) {
            toast.error('Vui Lòng Thêm Hình Ảnh');
          } else {
            toast.error('Cập Nhật Thất Bại');
          }
          setDisableButton(true)
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-red-500">
        CHỈNH SỬA BÀI VIẾT
      </div>
      <div>
        <Form
          size="large"
          name="wrap"
          form={form}
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="title_vi" />

          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />

          <UpLoadFile
            setFileId={setFileId}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
          />

          <Tinymce valueEditor={valueEditor} setValueEditor={setValueEditor} />

          <DescriptionForm label="Ghi chú :" name="description_vi" />

          <SwitchHome switchHome={switchHome} setSwitchHome={setSwitchHome} />

          <SwitchBanner
            switchBanner={switchBanner}
            setSwitchBanner={setSwitchBanner}
            setImageBannerId={setImageBannerId}
            imageBannerUrl={imageBannerUrl}
            setImageBannerUrl={setImageBannerUrl}
          />
          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}

export default UpdateNews;
