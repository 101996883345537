import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { Modal } from 'components/modal/Modal';
import { Notification } from 'components/Notification';
import { useUpdateOrderItemName } from 'api/orderItemApi';
import { OrderItemForm } from './OrderItemForm';

export const OrderItemModal = ({ onSuccess, initialValues, ...props }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const updateOrderItemNameMutation = useUpdateOrderItemName();

  const handleSubmitData = (values) => {
    const data = { id: initialValues?.id, ...values };

    updateOrderItemNameMutation.mutate(data, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({ id: 'message.updateSuccess' }),
        );
        onSuccess?.();
      },
      onError: () => {
        Notification(
          'error',
          intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      size="medium"
      title={`${intl.formatMessage({ id: 'common.modal.editTitle' })} - ${
        initialValues?.productCode
      }`}
      onOk={() => {
        form.validateFields().then(handleSubmitData);
      }}
      okButtonProps={{
        loading: updateOrderItemNameMutation.isLoading,
      }}
      {...props}
    >
      <OrderItemForm form={form} initialValues={initialValues} />
    </Modal>
  );
};
