import React, { memo, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function Tinymce({ valueEditor, setValueEditor }) {
  const editorRef = useRef(null);
  const handleChangeEditor = () => {
    if (editorRef.current) {
      // console.log(typeof editorRef.current.getContent());
      setValueEditor(editorRef.current.getContent());
    }
  };
  return (
    <>
      <div className="flex justify-between my-5">
        <p className='w-[100px] pl-6'>Nội dung : </p>
        <div className="ml-2 w-full">
          <Editor
            apiKey="dkt81z6uxljl8p1kntfycc5fh57kwfj7pwebz2wqt54xcs8l" // 05 / 02 / 2024
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={valueEditor}
            onChange={handleChangeEditor}
            init={{
              height: 500,
              menubar: false,
              selector: 'textarea',
              language: 'vi',
              plugins:
                'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker', // tinymcespellchecker
              toolbar:
                'undo redo | blocks fontfamily fontsize | forecolor backcolor bold italic underline strikethrough | link image media table mergetags | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | addcomment showcomments | spellcheckdialog a11ycheck typography  ',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              color_map: [
                '#BFEDD2',
                'Light Green',
                '#FBEEB8',
                'Light Yellow',
                '#F8CAC6',
                'Light Red',
                '#ECCAFA',
                'Light Purple',
                '#C2E0F4',
                'Light Blue',
                '#2DC26B',
                'Green',
                '#F1C40F',
                'Yellow',
                '#E03E2D',
                'Red',
                '#B96AD9',
                'Purple',
                '#3598DB',
                'Blue',
                '#169179',
                'Dark Turquoise',
                '#E67E23',
                'Orange',
                '#BA372A',
                'Dark Red',
                '#843FA1',
                'Dark Purple',
                '#236FA1',
                'Dark Blue',

                '#ECF0F1',
                'Light Gray',
                '#CED4D9',
                'Medium Gray',
                '#95A5A6',
                'Gray',
                '#7E8C8D',
                'Dark Gray',
                '#34495E',
                'Navy Blue',
                '#000000',
                'Black',
                '#ffffff',
                'White',
              ],
              custom_colors: true,
              tinycomments_mode: 'embedded',
              tinycomments_author: '',
              mergetags_list: [
                // { value: 'First.Name', title: 'First Name' },
                // { value: "Email", title: "Email" },
              ],
              // external_plugins: {
              //   'testing': 'http://www.testing.com/plugin.min.js',
              //   'maths': 'http://www.maths.com/plugin.min.js'
              // }
            }}
          />
        </div>
      </div>
    </>
  );
}

export default memo(Tinymce);
