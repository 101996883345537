import { CloudUploadOutlined } from '@ant-design/icons';
import { useUploadImage } from 'api/imageUpload';
import React, { memo, useState } from 'react';

function UploadImage({ setImageId, imageUrl, setImageUrl }) {

  const [image, setImage] = useState('');

  const useImageUploadMutation = useUploadImage();

  function handleImage(e) {
    setImage(e.target.files[0]);
  }
  function handleApi() {
    if (image) {
      const formData = new FormData();
      formData.append('file', image);
      useImageUploadMutation.mutate(formData, {
        onSuccess: (res) => {
          setImageId(res.data.id);
          setImageUrl(res.data.path);
        },
        onError: (error) => {
        },
      });
      // e.target.value = null;
      setImage(null);
    }
  }

  return (
    <div className="flex ml-[100px]">
      <label name="file_image" htmlFor="file_image" onMouseLeave={handleApi} className='flex justify-center bg-white px-10 py-5 w-[300px] rounded border border-dashed border-gray-300 text-center cursor-pointer text-[#08ca]'>
        <div className='my-auto'>
        <CloudUploadOutlined style={{ fontSize: '36px', color: '#08ca' }} />
        <p>Chọn Hình Ảnh </p>
        </div>
      </label>
        {imageUrl && (
          <div className="max-w-[160px] border border-gray-200 ml-2 shadow p-[1px] my-auto">
            <img src={imageUrl}></img>
          </div>
        )}
      <div className='hidden'>
        <input
          id='file_image'
          type="file"
          name="file_image"
          className="ml-2"
          onChange={handleImage}
        ></input>
        {/* {imageUrl && (
          <div className="w-[100px] mt-5 border border-green-500 ml-2 shadow p-1">
            <img src={imageUrl}></img>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default memo(UploadImage);
