import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'antd';
import { formatTime } from 'utils';
import { useDeletePost, useNewsList } from 'api/postApi';

import DeleteButton from 'components/button/DeleteButton';
import EditButton from 'components/button/EditButton';
import CreateNewButton from 'components/button/CreateNewButton';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    key: 'stt',
    width: '60px',
  },
  {
    title: 'Tên ',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    render: (value) => (
      <Link to={`/tin-tuc/edit/${value?.slug_vi}`} className="text-blue-500">
        {value?.title_vi}
      </Link>
    ),
  },
  {
    title: 'Trang Chủ',
    dataIndex: 'is_home',
    key: 'is_home',
    align: 'center',
    width: 120,
    render: (value) =>
      value == 1 ? (
        <div className="bg-green-500 rounded text-white">Hiển Thị</div>
      ) : (
        <div className="bg-red-500 rounded text-white">Ẩn</div>
      ),
  },
  {
    title: 'Banner',
    dataIndex: 'is_banner',
    key: 'is_banner',
    align: 'center',
    width: 100,
    render: (value) =>
      value == 1 ? (
        <div className="bg-green-500 rounded text-white">Hiển Thị</div>
      ) : (
        <div className="bg-red-500 rounded text-white">Ẩn</div>
      ),
  },
  {
    title: 'Ngày Cập Nhật',
    dataIndex: 'update_at',
    key: 'update_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Ngày Tạo',
    dataIndex: 'create_at',
    key: 'create_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
];

function News() {
  const [slugEdit, setSlugEdit] = useState();
  const [selected, setSelected] = useState([]);
  let { slug } = useParams();
  const [search, setSearch] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, refetch } = useNewsList({ slug: slug, ...argument });
  const deleteGuideMutation = useDeletePost();

  const dataSource = [];
  data?.data?.map((item, index) => {
    dataSource.push({
      id: item.id,
      key: index,
      stt: index + 1,
      title: item,
      is_home: item.is_home,
      is_banner: item.is_banner,
      update_at: item.updated_at,
      create_at: item.created_at,
    });
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSlugEdit(selectedRows[0]?.title?.slug_vi);
      setSelected(selectedRowKeys);
    },
  };

  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;
  return (
    <div>
      <div className="my-5 flex">
        <CreateNewButton slug={`/tin-tuc/create/${slug}`} />
        <EditButton slug={`/tin-tuc/edit/${slugEdit}`} selected={selected} />
        <DeleteButton
          selected={selected}
          refetch={refetch}
          deleteMutation={deleteGuideMutation}
        />
      </div>

      <Table
        bordered
        rowKey="id"
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        extra={renderExtra}
        pagination={{
          total: data?.meta?.total ?? 0,
          className: 'mr-5',
        }}
      />
    </div>
  );
}

export default News;
