import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';

export const healthApi = {
  getAll(params){
    return axiosClient.get('/health-package/category', {params});
  },
  getHealth(slug){
    return axiosClient.get(`/admin/health-package/category/${slug}`);
  },
  getHealthList(slug,params) {
    return axiosClient.get(`/health-package/list/${slug}?page=${params.page}&page_size=${params.page_size}`);
  },
  getHealthSlug(slug) {
    return axiosClient.get(`/health-package/${slug}`);
  },
  createCategory(data) {
    return axiosClient.post('/admin/health-package/category', data);
  },
  create(data) {
    return axiosClient.post('/admin/health-package', data);
  },
  updateCategory(data) {
    return axiosClient.patch(`/admin/health-package/category/${data.slug}`, data.data);
  },
  updateHealth(data) {
    return axiosClient.patch(`/admin/health-package/${data.slug}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/health-package/delete/multiple`,data);
  },
  deleteCategory(data) {
    return axiosClient.delete(`/admin/health-package/category/delete/multiple`,data);
  },
};

export const useHealthAll = ({params}) => {
  return useQuery({
      queryKey: ['health', params],
      queryFn: () => healthApi.getAll(params),

  })
}

  export const useHealthList = ({ slug,params, options } = {}) => {
    return useQuery({
      queryKey: ['health-list', slug,params],
      queryFn: () => healthApi.getHealthList(slug,params),
      ...options,
    });
  };

  export const useHealthSlug = ({ slug, options } = {}) => {
    return useQuery({
      queryKey: ['health-slug', slug],
      queryFn: () => healthApi.getHealthSlug(slug),
      ...options,
    });
  };

  export const useHealth = ({ slug, options } = {}) => {
    return useQuery({
      queryKey: ['health-category', slug],
      queryFn: () => healthApi.getHealth(slug),
      ...options,
    });
  };

  export const useCreateHealth = () => {
    return useMutation(healthApi.create);
  };

  export const useUpdateHealth = () => {
    return useMutation(healthApi.updateHealth);
  };

  export const useCreateCategory = () => {
    return useMutation(healthApi.createCategory);
  };

  export const useUpdateCategory = () => {
    return useMutation(healthApi.updateCategory);
  };

  export const useDeleteHealth = () => {
    return useMutation(healthApi.delete);
  };

  export const useDeleteCategory = () => {
    return useMutation(healthApi.deleteCategory);
  };