import { Table } from 'antd';
import { useDeleteVideo, useVideo } from 'api/videoLibraryApi';
import CreateNewButton from 'components/button/CreateNewButton';
import DeleteButton from 'components/button/DeleteButton';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatTime } from 'utils';

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    key: 'stt',
    width: '60px'
  },
  {
    title: 'Tên ',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    render : (value) => <Link to={`/tin-tuc/thu-vien-video/edit/${value?.id}`} className='text-blue-500'>{value?.title_vi}</Link>
  },
  {
    title: 'Ngày Cập Nhật',
    dataIndex: 'update_at',
    key: 'update_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
  {
    title: 'Ngày Tạo',
    dataIndex: 'create_at',
    key: 'create_at',
    align: 'center',
    render: (value) => formatTime(value, 'DD/MM/YYYY'),
  },
];
export default function VideoLibrary() {
  const [selected,setSelected] = useState([]);
  const [ id, setId ] = useState();
  const [search, setSearch] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, refetch } = useVideo(argument);
  const deleteVideoMutation = useDeleteVideo();

  const dataSource = [];
  data?.data?.map((item,index) => {
    dataSource.push({
      stt: index + 1,
      title: item,
      update_at: item.updated_at,
      create_at: item.created_at,
      id: item.id
    })
  })

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setId(selectedRows[0]?.title?.id)
      setSelected(selectedRowKeys)
    },
  };

  const handleChange = ({ current, pageSize }) =>
  setSearch({ ...search, page: current, page_size: pageSize });
const renderExtra = <></>;
  return (
    <div>
      <div className="title text-blue-500">
        THƯ VIỆN VIDEO
      </div>

      <div className='mb-5 flex'>
        <CreateNewButton slug={`create`} />
        <div className='ml-2'></div>
        <DeleteButton
          selected={selected}
          refetch={refetch}
          deleteMutation={deleteVideoMutation}
        />
      </div>

      <Table
        bordered
        rowKey="id"
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        extra={renderExtra}
        pagination={{
          total: data?.meta?.total ?? 0,
          className:"mr-5",
        }}
      />
    </div>
  );
}
