import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useCreatePost, useNewsMenu } from 'api/postApi';
import SwitchHome from 'components/switch/SwitchHome';
import SwitchBanner from 'components/switch/SwitchBanner';
import DescriptionForm from 'components/formItem/DescriptionForm';
import UploadImage from 'components/uploadImage/UploadImage';
import NameForm from 'components/formItem/NameForm';
import SaveButton from 'components/button/SaveButton';
import Tinymce from 'components/tinymceEditor/Tinymce';
// import { ChooseFile } from 'components/chooseFile/ChooseFile';
import UpLoadFile from 'components/uploadImage/UpLoadFile';

function CreateNews() {
  const [disableButton, setDisableButton] = useState(true);
  const { slug } = useParams();
  const [valueEditor, setValueEditor] = useState('');
  console.log('editor', valueEditor)
  const [switchHome, setSwitchHome] = useState(false);
  const [switchBanner, setSwitchBanner] = useState(false);
  const [imageBannerId, setImageBannerId] = useState();
  const [imageBannerUrl, setImageBannerUrl] = useState();
  const [categoryId, setCategoryId] = useState();
  const navigate = useNavigate();
  const { data } = useNewsMenu();

  useEffect(() => {
    data?.data?.map((item) => {
      if (slug === item.slug_vi) {
        setCategoryId(item.id);
      }
    });
  });

  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [fileId, setFileId] = useState();
  const [fileUrl, setFileUrl] = useState();
  const useCreateGuideMutation = useCreatePost();

  const onFinish = (values) => {
    setDisableButton(false)
    const data = {
      is_home: switchHome ? 1 : 0,
      is_banner: switchBanner ? 1 : 0,
      banner_vi: switchBanner ? imageBannerId : '',
      thumbnail_vi: imageId,
      file_vi_id: fileId,
      content_vi: valueEditor || ' ',
      category_id: categoryId,
      ...values,
    };
    useCreateGuideMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          toast.success('Thêm Mới Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          if (!imageId) {
            toast.error('Vui Lòng Thêm Hình Ảnh');
          } else {
            toast.error('Thêm Mới Thất Bại');
          }
          setDisableButton(true)
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-blue-500 ">TẠO MỚI BÀI VIẾT</div>
      <div >
        <Form
          size="large"
          name="wrap"
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="title_vi" />

          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />

          {/* <ChooseFile/> */}
          
          <UpLoadFile
            setFileId={setFileId}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
          />

          <Tinymce setValueEditor={setValueEditor} />

          <DescriptionForm label="Ghi chú :" name="description_vi" />

          <SwitchHome switchHome={switchHome} setSwitchHome={setSwitchHome} />

          <SwitchBanner
            switchBanner={switchBanner}
            setSwitchBanner={setSwitchBanner}
            setImageBannerId={setImageBannerId}
            imageBannerUrl={imageBannerUrl}
            setImageBannerUrl={setImageBannerUrl}
          />
          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}
export default CreateNews;
