import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/common';
import { PATH_NAME } from 'constants/routes';

export const normalizeOrderData = (data = {}) => {
  let {
    livestreamDate = null,
    status = null,
    note = null,
    orderAddress = {},
    items = [],
    shopId,
    customerId,
  } = data;

  orderAddress = _.pick(orderAddress, [
    'fullName',
    'email',
    'phone',
    'address',
    'provinceId',
    'districtId',
  ]);

  items = items.map(
    ({ productId, productName, quantity, price, productCode, id }) => ({
      productId,
      name: productName,
      price: +price,
      quantity,
      code: productCode,
      id,
    }),
  );

  return {
    livestreamDate: livestreamDate ? moment(livestreamDate, DATE_FORMAT) : null,
    status,
    note,
    orderAddress,
    items,
    shopId,
    customerId,
  };
};

export const normalizeSubmitData = (
  { customerId, ...data },
  isUpdate = false,
) => {
  data.livestreamDate = data.livestreamDate?.format(DATE_FORMAT);

  if (isUpdate) {
    data.orderAddress.customerId = customerId;
  }

  const { items, ...restData } = data;

  return {
    ...restData,
    items: items.map(({ name: productName, ...restItem }) => ({
      productName,
      ...restItem,
    })),
  };
};

export const getPublicOrderLink = (code) =>
  `${window.location.origin}${PATH_NAME.CUSTOMER_ORDER}?code=${code}`;
