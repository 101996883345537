import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import TextArea from 'antd/lib/input/TextArea';
import { useCreateQA } from 'api/QA';
import SaveButton from 'components/button/SaveButton';
import NameForm from 'components/formItem/NameForm';

export default function CreateQA() {
  const [disableButton, setDisableButton] = useState(true);
  const navigate = useNavigate();
  const useCreateGuideMutation = useCreateQA();

  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      ...values,
    };
    // console.log('Success : ', data);
    useCreateGuideMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          // console.log(response);
          toast.success('Tạo Mới Chủ Đề Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          // console.log('error : ', error);
          toast.error('Tạo Mới Chủ Đề Thất Bại');
          setDisableButton(true);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="text-blue-500 title">TẠO MỚI CHỦ ĐỀ</div>

      <div>
        <Form
          size="large"
          name="wrap"
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Chủ đề :" name="topic_vi" />

          <Form.Item
            label="Câu Hỏi :"
            name="question_vi"
            rules={[
              { required: true, message: 'Vui lòng nhập nội dung câu hỏi ' },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>

          <Form.Item
            label="Câu Trả Lời :"
            name="answer_vi"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập nội dung câu trả lời ',
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}
