import { Form } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useService, useUpdateService } from 'api/serviceApi';
import NameForm from 'components/formItem/NameForm';
import UploadImage from 'components/uploadImage/UploadImage';
import SaveButton from 'components/button/SaveButton';
import Tinymce from 'components/tinymceEditor/Tinymce';
import { useHealthSlug, useUpdateHealth } from 'api/healthApi';

function UpdateHealthList() {
  const [disableButton, setDisableButton] = useState(true);
  const [valueEditor, setValueEditor] = useState('');
  const navigate = useNavigate();
  const { slugDetail } = useParams();
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const useUpdateHealthMutation = useUpdateHealth();
  const [form] = Form.useForm();

  const { data } = useHealthSlug({
    slug: slugDetail,
    options: {
      onSuccess(res) {
        const data = res.data;
        setImageId(data.thumbnail_vi.id);
        setImageUrl(data.thumbnail_vi.path);
        setValueEditor(data?.content_vi);
        form.setFieldsValue({
          name_vi: data.name_vi,
          name_en: data.name_en,
          name_ru: data.name_ru,
          // content_vi: data.content_vi,
          // content_en: data.content_en,
          // content_ru: data.content_ru,
          thumbnail_vi: data.thumbnail_vi?.id,
          thumbnail_en: data.thumbnail_en?.id,
          thumbnail_ru: data.thumbnail_ru?.id,
        });
      },
    },
  });

  const categoryId = data?.data?.health_package_catagory_id;
  console.log('data',categoryId)

  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      thumbnail_vi: imageId,
      health_package_catagory_id: categoryId,
      content_vi: valueEditor,
      ...values,
    };
    useUpdateHealthMutation.mutate(
      { slug: slugDetail, data },
      {
        onSuccess: (response) => {
          toast.success('Cập Nhật Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          if (!imageId) {
            toast.error('Vui Lòng Thêm Hình Ảnh');
          } else {
            toast.error('Cập Nhật Thất Bại');
          }
          setDisableButton(true);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-red-500">CHỈNH SỬA BÀI VIẾT</div>
      <div>
        <Form
          size="large"
          name="wrap"
          form={form}
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="name_vi" />

          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />

          <Tinymce valueEditor={valueEditor} setValueEditor={setValueEditor} />

          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}

export default UpdateHealthList;
