import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useCreateGuide, useGuideList } from 'api/guideApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import SaveButton from 'components/button/SaveButton';
import UploadImage from 'components/uploadImage/UploadImage';
import NameForm from 'components/formItem/NameForm';
import Tinymce from 'components/tinymceEditor/Tinymce';
import UpLoadFile from 'components/uploadImage/UpLoadFile';

function CreateGuide() {
  const [disableButton, setDisableButton] = useState(true);
  const [valueEditor, setValueEditor] = useState('');
  const [fileId, setFileId] = useState();
  const [fileUrl, setFileUrl] = useState();
  const navigate = useNavigate();
  const { slug } = useParams();
  const argument = {
    page: 1,
    page_size: 10,
    options: {
      keepPreviousData: true,
    },
  };
  const { data } = useGuideList({ slug: slug, params: argument });
  const categoryId = data?.data[0]?.category_id;
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const useCreateGuideMutation = useCreateGuide();

  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      thumbnail_vi: imageId,
      category_id: categoryId,
      content_vi: valueEditor,
      file_vi_id: fileId,
      ...values,
    };

    useCreateGuideMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          toast.success('Thêm Mới Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          if (!imageId) {
            toast.error('Vui Lòng Thêm Hình Ảnh');
          } else {
            toast.error('Thêm Mới Thất Bại');
          }
          setDisableButton(true);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-blue-500">TẠO MỚI BÀI VIẾT</div>
      <div>
        <Form
          size="large"
          name="wrap"
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="name_vi" />

          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />

          <UpLoadFile
            setFileId={setFileId}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl} />

          <Tinymce valueEditor={valueEditor} setValueEditor={setValueEditor} />

          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}
export default CreateGuide;
