import axiosClient from './axiosClient';
import { useQuery, useMutation } from 'react-query';


export const QAApi = {
  getQA(params){
    return axiosClient.get(`admin/question-answer?page=${params.page}&page_size=${params.page_size}`);
  },
  getQADetail(id){
    return axiosClient.get(`/admin/question-answer/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/question-answer', data);
  },
  update(data) {
    return axiosClient.patch(`/admin/question-answer/${data.id}`, data.data);
  },
  delete(data) {
    return axiosClient.delete(`/admin/question-answer/delete/multiple`,data);
  },
};

export const useQA = ({ params, options } = {}) => {
  return useQuery({
      queryKey: ['QA'],
      queryFn: () => QAApi.getQA(params),
  })
}

  export const useQADetail = ({ id, options } = {}) => {
    return useQuery({
      queryKey: ['QA-detail', id],
      queryFn: () => QAApi.getQADetail(id),
      ...options,
    });
  };

  export const useCreateQA = () => {
    return useMutation(QAApi.create);
  };

  export const useUpdateQA = () => {
    return useMutation(QAApi.update);
  };

  export const useDeleteQA = () => {
    return useMutation(QAApi.delete);
  };