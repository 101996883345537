import { Form } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

import { useCreatePrice } from 'api/guideApi';
import DescriptionForm from 'components/formItem/DescriptionForm';
import SaveButton from 'components/button/SaveButton';
import NameForm from 'components/formItem/NameForm';
import UpLoadFile from 'components/uploadImage/UpLoadFile';

function CreatePriceService() {
  const [ disableButton, setDisableButton ] = useState(true);
  const navigate = useNavigate();
  const [fileId, setFileId] = useState();
  const [fileUrl, setFileUrl] = useState();
  const useCreateGuideMutation = useCreatePrice();

  const onFinish = (values) => {
    setDisableButton(false)
    const data = {
      file_vi_id: fileId,
      ...values,
    };
    useCreateGuideMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          toast.success('Tạo Mới Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          if (!fileId) {
            toast.error('Vui Lòng Thêm File');
          } else {
            toast.error('Tạo Mới Thất Bại');
          }
    setDisableButton(true)
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-blue-500">TẠO MỚI BÀI VIẾT</div>
      <div>
        <Form
          size="large"
          name="wrap"
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="name_vi" />

          <UpLoadFile
            setFileId={setFileId}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
          />

          <DescriptionForm label="Ghi chú :" name="description_vi" />

          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}
export default CreatePriceService;
