import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useCreateCategory, useHealth, useUpdateCategory } from 'api/healthApi';
import SaveButton from 'components/button/SaveButton';
import NameForm from 'components/formItem/NameForm';
import UploadImage from 'components/uploadImage/UploadImage';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function EditCategory({
  isModalOpen,
  setIsModalOpen,
  refetch,
  slug,
}) {
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [form] = Form.useForm();

  const useUpdateCategoryMutation = useUpdateCategory();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { } = useHealth({
    slug: slug,
    options: {
      onSuccess(res) {
        const data = res.data;
        setImageId(data.thumbnail_vi.id);
        setImageUrl(data.thumbnail_vi.path);
        form.setFieldsValue({
          name_vi: data.name_vi,
          name_en: data.name_en,
          name_ru: data.name_ru,
          thumbnail_vi: data.thumbnail_vi?.id,
          thumbnail_en: data.thumbnail_en?.id,
          thumbnail_ru: data.thumbnail_ru?.id,
        });
      },
    },
  });
  const onFinish = (values) => {
    const data = {
      slug,
      data: {
        thumbnail_vi: imageId,
        ...values
      }
    };
    console.log('data: ', data)
    useUpdateCategoryMutation.mutate(
      { ...data },
      {
        onSuccess: (response) => {
          toast.success('Thêm Mới Thành Công');
          refetch();
          setIsModalOpen(false);
        },
        onError: (error) => {
          toast.error('Thêm Mới Thất Bại');
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Modal
      className="mt-[150px]"
      title="CHỈNH SỬA"
      width={1000}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        size="large"
        layout="vertical"
        name="wrap"
        labelWrap
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <NameForm label="Tên :" name="name_vi" />

        <div className=" -translate-x-[100px] mt-5">
          <UploadImage
            setImageId={setImageId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />
        </div>

        <div className=" relative mt-5 mb-8 border-t border-collapse">
          <div className=" absolute top-0 right-0 pt-2">
            <SaveButton />
          </div>
        </div>
      </Form>
    </Modal>
  );
}
