import { Form } from 'antd';
import React, { useState } from 'react';
import { useDetailPrice, useUpdatePrice } from 'api/guideApi';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import UpLoadFile from 'components/uploadImage/UpLoadFile';
import NameForm from 'components/formItem/NameForm';
import DescriptionForm from 'components/formItem/DescriptionForm';
import SaveButton from 'components/button/SaveButton';

export default function UpdatePriceService() {
  const [disableButton, setDisableButton] = useState(true);

  const navigate = useNavigate();
  const { slugDetail } = useParams();
  const [fileId, setFileId] = useState();
  const [fileUrl, setFileUrl] = useState();
  const useUpdateGuideMutation = useUpdatePrice();
  const [form] = Form.useForm();

  const { data } = useDetailPrice({
    slug: slugDetail,
    options: {
      onSuccess(res) {
        console.log('res', res.data);
        const data = res.data;
        setFileUrl(data.file_vi_id.path);
        form.setFieldsValue({
          name_vi: data.name_vi,
          name_en: data.name_en,
          name_ru: data.name_ru,
          file_vi_id: data.file_vi_id?.id,
          file_en_id: data.file_en_id?.id,
          file_ru_id: data.file_ru_id?.id,
          description_vi: data.description_vi,
          description_en: data.description_en,
          description_ru: data.description_ru,
        });
      },
    },
  });
  const categoryId = data?.data?.category_id;
  const onFinish = (values) => {
    setDisableButton(false);
    const data = {
      file_vi_id: fileId,
      category_id: categoryId,
      ...values,
    };
    // console.log('Success : ', data);
    useUpdateGuideMutation.mutate(
      { slug: slugDetail, data },
      {
        onSuccess: (response) => {
          // console.log(response);
          toast.success('Cập Nhật Thành Công');
          navigate(-1);
        },
        onError: (error) => {
          // console.log('error : ', error);
          if (!fileId) {
            toast.error('Vui Lòng Thêm File');
          } else {
            toast.error('Cập Nhật Thất Bại');
          }
          setDisableButton(true);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className="title text-red-500">CHỈNH SỬA BÀI VIẾT</div>
      <div>
        <Form
          size="large"
          name="wrap"
          form={form}
          labelCol={{
            flex: '100px',
          }}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <NameForm label="Tên :" name="name_vi" />

          <UpLoadFile
            setFileId={setFileId}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
          />

          <DescriptionForm label="Ghi chú :" name="description_vi" />

          <SaveButton disable={disableButton} />
        </Form>
      </div>
    </div>
  );
}
