import { Button, Checkbox, Pagination } from 'antd';
import { useDeleteImage, useImage } from 'api/imageLibraryApi';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import CreateImageLibrary from './CreateImageLibrary';
import UpdateImageLibrary from './UpdateImageLibrary';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

export default function ImageLibrary() {
  const [modalCreateImage, setModalCreateImage] = useState(false);
  const [id, setId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteMultiplePhotos, setDeleteMultiplePhoto] = useState(false);
  const [deleteChecked, setDeleteChecked] = useState([]);
  const [count, setCount] = useState(0);
  const deleteImagesMutation = useDeleteImage();
  const [search, setSearch] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, refetch } = useImage(argument);

  const onChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setCount(count + 1);
      deleteChecked.push(value);
    } else {
      setCount(count - 1);
      const test = deleteChecked.filter((idx) => idx != value);
      setDeleteChecked(test);
    }
  };

  function handleDeleteMutiple() {
    deleteImagesMutation.mutate(
      { data: { ids: deleteChecked } },
      {
        onSuccess: () => {
          toast.success(`Xóa Thành Công`);
          refetch();
        },
        onError: () => {
          toast.error(`Xóa Thất Bại`);
        },
      },
    );
  }

  const showModal = (value) => {
    setIsModalOpen(true);
    setId(value.target.id);
    console.log(value.target.id);
  };

  function handleChangePagination(current, pageSize) {
    setSearch({ ...search, page: current, page_size: pageSize });
  }
  return (
    <div>
      <div className="title text-blue-500">THƯ VIỆN ẢNH</div>

      <CreateImageLibrary
        modalCreateImage={modalCreateImage}
        setModalCreateImage={setModalCreateImage}
        refetch={refetch}
      />

      <UpdateImageLibrary
        id={id}
        refetch={refetch}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />

      <div className="mb-5">
        <Button
          className="bg-blue-500 text-white rounded mr-2"
          onClick={() => setModalCreateImage(true)}
        >
          Thêm Hình Ảnh
        </Button>
        <Button
          className={
            deleteMultiplePhotos
              ? 'bg-red-500 text-white rounded'
              : 'bg-blue-500 text-white rounded'
          }
          onClick={() => setDeleteMultiplePhoto(!deleteMultiplePhotos)}
        >
          {deleteMultiplePhotos ? (
            deleteChecked.length == 0 ? (
              <div onClick={() => toast.warn('Vui lòng chọn ảnh muốn xóa')}>
                Chọn Hình Ảnh Cần Xóa
              </div>
            ) : (
              <div
                onClick={handleDeleteMutiple}
              >{`Xóa ${count} Hình Ảnh Đã Chọn`}</div>
            )
          ) : (
            'Chọn Hình Ảnh'
          )}
        </Button>
      </div>

      <div className="grid sm:grid-flow-col-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
        {data?.data?.map((item, index) => (
          <div key={item.id}>
            <div
              className="relative group cursor-pointer h-[170px] overflow-hidden border border-collapse"
              onClick={showModal}
            >
              <img id={item.id} src={item.file_id.path} />
            </div>
            <div className="flex my-2">
              <div className={deleteMultiplePhotos ? '' : 'invisible'}>
                <Checkbox value={item.id} onChange={onChange}></Checkbox>
              </div>
              <div className="text-center font-bold ml-2 ">
                {item.title_vi || `vietsov image ${index}`}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        className="flex justify-end mb-2"
        defaultCurrent={1}
        total={data?.meta?.total ?? 0}
        onChange={handleChangePagination}
      />
    </div>
  );
}
